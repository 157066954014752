import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          //  Utilized Figma text, Exported to SVG, open and edited path on yqnn.github.io/svg-path-editor/
          d="M 42.7181 22.8381 H 48.3168 V 52.5113 C 48.3168 56.617 47.0104 59.603 44.7709 61.8425 C 42.3448 64.2686 38.9856 65.3884 35.0665 65.3884 C 30.9608 65.3884 27.6015 64.2686 25.362 62.2157 C 22.9359 59.9763 21.8162 56.9903 21.8162 53.2578 H 27.2283 C 27.2283 55.4973 27.9748 57.3635 29.2812 58.8565 C 30.5875 60.1629 32.4538 60.7228 35.0665 60.7228 C 37.306 60.7228 39.1722 60.1629 40.4786 58.6699 C 41.9716 57.1769 42.7181 55.124 42.7181 52.5113 V 22.8381 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
